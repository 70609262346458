import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { ABOUT, TRANSITION_TIME, TABLET_MIN_WIDTH } from '../../constants'
import { getSectionHeight } from '../../utils';
import Header from '../Header';
import Footer from './components/Footer';
import Content from './components/Content';
import './styles.css'

const getClassName = (page) => {
  if (page === ABOUT) return 'about'
  return 'about about--closed'
}

const getVisibleClassName = (page) => {
  if (page === ABOUT) return 'about about--visible'
  return 'about about--closed'
}

const isActive = (page) => {
  return (page === ABOUT)
}

const ContentComponent = ({ page, content }) => {
  if (!content || !isActive(page)) return null
  return <Content {...content} />
}

const FooterComponent = ({ page, footer, hasScroll }) => {
  if (!footer || !isActive(page)) return null
  return <Footer {...footer} hasScroll={hasScroll} />
}

const OpenButton = ({ page }) => {
  if (isActive(page)) return null
  return <Link className="about__open" to="/about"></Link>
}

const About = ({ data, page, height }) => {
  const mainClassName = getClassName(page)
  const [className, setClassName] = useState(mainClassName)
  const [heightStyle, setHeightStyle] = useState({})
  const [hasScroll, setHasScroll] = useState(false)

  const elm = useRef(null)

  const content = data?.prismic?.allAbouts?.edges?.[0]?.node
  const footer = data?.prismic?.allFooters?.edges?.[0]?.node

  useEffect(() =>{
    const mainClassName = getClassName(page)
    setClassName(mainClassName)

    const visibleClassName = getVisibleClassName(page)

    if (window !== 'undefined' && window.innerWidth >= TABLET_MIN_WIDTH) {
      elm.current.scrollIntoView({ behavior: "auto" })
    }
    
    setTimeout(() => {
      setClassName(visibleClassName)
      setHasScroll(elm?.current?.clientHeight > window.innerHeight)
    }, TRANSITION_TIME);
  }, [page])

  useEffect(() => {
    setHeightStyle(getSectionHeight(isActive(page)))
  }, [height, page])

  return (
    <div className={className} style={{ ...heightStyle }} ref={elm}>
      <OpenButton page={page} />
      <Header section={ABOUT} page={page} sectionName="About" isActive={isActive(page)} />
      <ContentComponent page={page} content={content} />
      <FooterComponent page={page} footer={footer} hasScroll={hasScroll} />
    </div>
  )
}

export default About;