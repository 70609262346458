import React, { useState, useEffect } from 'react';
import Project from '../Project';
import { getDeviceType } from '../../utils';
import './styles.css';

const Projects = ({ data }) => {
  const [className, setClassName] = useState("projects");

  useEffect(() => {
    if (!getDeviceType()) return
    setTimeout(() => {
      setClassName("projects projects--visible")
    }, 10);
  }, [])

  const projects = data?.prismic?.allProjects?.edges
  if (!projects?.length) return null

  return (
    <div className={className}>
      <ul className="projects__list">
        {projects.map(({ node }) => {
          const { _meta: { uid } } = node
          return <Project key={`project-${uid}`} {...node} />
        })}
      </ul>
    </div>
  )
}

export default Projects;