import React from 'react';
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import ImageResponsive from '../ImageResponsive';
import './styles.css';

const isVertical = ({ height, width }) => height > width

const Image = ({ name, thumbnail: { url, dimensions }, format }) => {
  if (isVertical(dimensions)) return <img src={url} alt={name} />
  return <ImageResponsive src={url} alt={name} />
}

const getClassName = (image) => {
  const { dimensions } = image
  if (isVertical(dimensions)) return 'project project--vertical'
  return 'project'
}

const Project = ({ _meta: { uid }, thumbnail, name, format }) => (
  <li className={getClassName(thumbnail)}>
    <Link to={`/project/${uid}`}>
      <div className="project__thumbnail">
        <Image {...{ name, thumbnail, format }} />
      </div>
      <h2>{RichText.asText(name)}</h2>
    </Link>
  </li>
)

export default Project;
