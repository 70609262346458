module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"architecture-doing-place","short_name":"adop","start_url":"/","background_color":"#43509b","theme_color":"#43509b","display":"minimal-ui","icon":"src/images/adop-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2bdb8cfb72b0112c73157d3aac84bddb"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"adpdashboard","defaultLang":"en-gb","shortenUrlLangs":true,"path":"/preview","previews":false,"sharpKeys":[{}],"pages":[{"type":"Project","match":"project/:uid","previewPath":"/project","component":"/builds/alexgrcs/architecture-doing-place/src/templates/project.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["SchulbuchNordPro"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
