const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const getDate = (date) => {
  const dateData = new Date(date)
  const month = monthNames[dateData.getMonth()]
  const year = dateData.getFullYear()

  return `${month} ${year}`
}

const mapBody = (body) => body.map(({ type, primary }) => ({
  type,
  text: primary?.text || null,
  image: primary?.image || null
}))

const mapNewData = ({ node }) => {
  const { title, date, body } = node

  return {
    title,
    date,
    content: mapBody(body)
  }
}

export const mapNews = (data) => {
  const newsData = data?.prismic?.allNewss?.edges
  if (!newsData) return null

  return newsData.map(item => mapNewData(item))
}
