import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { ARTICLE, HOME, TRANSITION_TIME, TABLET_MIN_WIDTH } from '../../constants'
import Header from '../Header';
import Projects from '../Projects';
import Article from '../Article';
import Footer from '../Footer';
import './styles.css';
import Title from '../Title';
import { getSectionHeight } from '../../utils/utils';

const Page = ({ page, data }) => {
  if (page === ARTICLE) return <Article data={data} />
  if (page === HOME) return <Projects data={data} />
}

const isActive = (page) => {
  return (page === HOME || page === ARTICLE)
}

const getClassName = (page, lastPage) => {
  if (isActive(page)) return 'work'
  return 'work work--closed'
}

const getVisibleClassName = (page) => {
  if (isActive(page)) return 'work work--visible'
  return 'work work--closed'
}

const ContentComponent = ({ page, data }) => {
  if (!isActive(page)) return null
  return <Page page={page} data={data} />
}

const FooterComponent = ({ page, footer }) => {
  if (!footer || !isActive(page)) return null
  return <Footer page={page} {...footer} />
}

const OpenButton = ({ page }) => {
  if (isActive(page)) return null
  return <Link className="work__open" to="/"></Link>
}

const Work = ({ data, page, height }) => {
  const mainClassName = getClassName(page)
  const [className, setClassName] = useState(mainClassName)
  const [lastPage, setLastPage] = useState(page)
  const [heightStyle, setHeightStyle] = useState({})

  const elm = useRef(null)

  const footer = data?.prismic?.allFooters?.edges?.[0]?.node

  useEffect(() => {
    const visibleClassName = getVisibleClassName(page)
    const previousPage = lastPage
    setLastPage(page)
    
    let mainClassName = getClassName(page)
    if (isActive(previousPage) && isActive(page)) {
      mainClassName = visibleClassName
    }
    setClassName(mainClassName)

    if (window !== 'undefined' && window.innerWidth >= TABLET_MIN_WIDTH) {
      elm.current.scrollIntoView({ behavior: "auto" })
    }

    setTimeout(() => {
      setClassName(visibleClassName)
    }, TRANSITION_TIME);
  }, [page])

  useEffect(() => {
    setHeightStyle(getSectionHeight(isActive(page)))
  }, [height, page])

  return (
    <div className={className} style={{ ...heightStyle }} ref={elm}>
      <OpenButton page={page} />
      <Header sectionName="Work" isMain isActive={isActive(page)}>
        <Title page={page} />
      </Header>
      <ContentComponent page={page} data={data} />
      <FooterComponent page={page} footer={footer} />
    </div>
  )
}

export default Work;