import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import ImageResponsive from '../ImageResponsive';
import Slider from '../Slider';
import { mapArticle } from './utils';
import { getDeviceType } from '../../utils';
import './styles.css'

const Caption = ({ caption }) => {
  if (!caption) return null

  return (
    <div className="article__image__caption">
      <p>{caption}</p>
    </div>
  )
}

const Slice = ({ fields, text, image, name }) => {
  if (fields) {
    return (
      <div className="article__slider">
        <Slider items={fields} />
      </div>
    )
  }

  if (text) {
    return (
      <div className="article__text">
        {RichText.render(text)}
      </div>
    )
  }

  if (image) {
    return (
      <div className="article__image">
        <ImageResponsive src={image.url} alt={image.alt || name} />
        <Caption caption={image.alt} />
      </div>
    )
  }
}

const Body = ({ content, date, name }) => {
  if (!content?.length) return null

  return content.map((item, index) => <Slice key={`${date}-${index}`} name={name} {...item} />)
}

const Article = ({ data }) => {
  const [className, setClassName] = useState("article");

  useEffect(() => {
    if (!getDeviceType()) return
    setTimeout(() => {
      setClassName("article article--visible")
    }, 10);
  }, [])

  const article = mapArticle(data)
  if (!article) return null

  const { name, date, content } = article

  return (
    <>
      <article className={className}>
        <div className="article__wrapper">
          {RichText.render(name)}
          <Body { ...{ content, date, name } } />
        </div>
      </article>
      <Link to="/" className="article__close" />
      <Link to="/" className="article__close article__close--top" />
    </>
  )
}

export default Article;