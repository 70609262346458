import React from 'react';
import { RichText } from 'prismic-reactjs';
import { ARTICLE, NEWS } from '../../constants'
import './styles.css';

const getClassName = (page) => {
  if (page === ARTICLE) return 'footer footer--article'
  if (page === NEWS) return 'footer footer--news'
  return 'footer'
}

const Footer = ({ page, instagram, linkedin, newsletter, copyright }) => {

  return (
    <footer className={getClassName(page)} page={page}>
      <p>
        <a href={RichText.asText(instagram)} target="_blank" rel="noreferrer">Instagram</a>
        <span> · </span>
        <a href={RichText.asText(linkedin)} target="_blank" rel="noreferrer">Linkedin</a>
        <span> · </span>
        <a href={RichText.asText(newsletter)} target="_blank" rel="noreferrer">Newsletter</a>
        <br />
        <span>{RichText.asText(copyright)}</span>
      </p>
    </footer>
  )
}

export default Footer;