import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { NEWS, TRANSITION_TIME, HOME, ARTICLE, TABLET_MIN_WIDTH } from '../../constants'
import { getSectionHeight } from '../../utils/utils';
import Header from '../Header';
import Footer from '../Footer';
import Content from './components/Content';
import { mapNews } from './utils';
import './styles.css'

const getClassName = (page) => {
  let className = 'news'

  if (page !== NEWS) {
    className = className + ' news--closed'
  }
  
  if (page === HOME || page === ARTICLE) {
    className = className + ' news--home'
  }

  return className
}

const getVisibleClassName = (page) => {
  let className = 'news'

  if (page === NEWS) {
    className = className + ' news--visible'
  } else {
    className = className + ' news--closed'
  }
  
  if (page === HOME || page === ARTICLE) {
    className = className + ' news--home'
  }

  return className
}

const isActive = (page) => {
  return (page === NEWS)
}

const ContentComponent = ({ page, news }) => {
  if (!news?.length || !isActive(page)) return null
  return <Content page={page} news={news} />
}

const FooterComponent = ({ page, footer }) => {
  if (!footer || !isActive(page)) return null
  return <Footer page={page} {...footer} />
}

const OpenButton = ({ page }) => {
  if (isActive(page)) return null
  return <Link className="news__open" to="/news"></Link>
}

const News = ({ data, page, height }) => {
  const mainClassName = getClassName(page)
  const [className, setClassName] = useState(mainClassName)
  const [heightStyle, setHeightStyle] = useState({})

  const elm = useRef(null)

  const news = mapNews(data)
  const footer = data?.prismic?.allFooters?.edges?.[0]?.node

  useEffect(() =>{
    const mainClassName = getClassName(page)
    setClassName(mainClassName)

    const visibleClassName = getVisibleClassName(page)
    
    if (window !== 'undefined' && window.innerWidth >= TABLET_MIN_WIDTH) {
      elm.current.scrollIntoView({ behavior: "auto" })
    }

    setTimeout(() => {
      setClassName(visibleClassName)
    }, TRANSITION_TIME);
  }, [page])

  useEffect(() => {
    setHeightStyle(getSectionHeight(isActive(page)))
  }, [height, page])

  return (
    <div className={className} style={{ ...heightStyle }} ref={elm}>
      <OpenButton page={page} />
      <Header section={NEWS} page={page} sectionName="News" isActive={isActive(page)} />
      <ContentComponent page={page} news={news} />
      <FooterComponent page={page} footer={footer} />
    </div>
  )
}

export default News;