import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs';

const Logos = ({ logos }) => {
  if (!logos) return null

  return (
    <div className="about__banner">
      <img src={logos.url} alt={logos.alt} />
    </div>
  )
}

const CollapsedText = ({ collapsed, isVisible }) => {
  if (!isVisible) return null
  return RichText.render(collapsed)
}

const ReadMore = ({ about, isVisible, onClick }) => {
  if (isVisible) return RichText.render(about)

  return (
    <div className="about__content__wrapper">
      {RichText.render(about)}
      <span className="about__content__dots">...</span>
      <span className="about__content__read-more" onClick={onClick}>(Read more)</span>
    </div>
  )
}

const Content = ({ about, address, collapsed, call, email, logos }) => {
  const [isVisible, setIsVisible] = useState(false)

  const onClick = () => setIsVisible(!isVisible)

  return (
    <div className="about__content">
      <div className="about__contact">
        
        <ul className="about__contact-data">
          <li>
            <span className="about__label">Email</span>
              <span className="about__value"><a href={`mailto:${RichText.asText(email)}`}>{RichText.asText(email)}</a></span>
          </li>
          <li>
            <span className="about__label">Call</span>
            <span className="about__value">{RichText.asText(call)}</span>
          </li>
        </ul>
        {RichText.render(address)}
        <ReadMore { ...{ about, isVisible, onClick } } />
        <CollapsedText { ...{ collapsed, isVisible } } />
      </div>
      <Logos logos={logos} />
    </div>
  )
}

export default Content