import { ABOUT, NEWS, ARTICLE, HOME, MOBILE_HEADER_HEIGHT, MOBILE_MIN_WIDTH, TABLET_MIN_WIDTH, TABLET_HEADER_HEIGHT } from "../constants"

export const getPage = location => {
  if (location?.pathname.includes('about')) return ABOUT
  if (location?.pathname.includes('news')) return NEWS
  if (location?.pathname.includes('project')) return ARTICLE
  return HOME
}

export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'project') {
    return `/project/${doc.uid}`
  }
 
  // URL for a page type
  if (doc.type === 'about') {
    return `/${doc.uid}`
  }
 
  // Backup for all other types
  return '/'
}

const TABLET = 'tablet'
const MOBILE = 'mobile'

export const getDeviceType = () => {
  if (typeof window === 'undefined') return false
  if (window.innerWidth <= MOBILE_MIN_WIDTH) return MOBILE
  if (window.innerWidth <= TABLET_MIN_WIDTH) return TABLET
}

export const getSectionHeight = (isActive) => {
  const deviceType = getDeviceType()
  if (!isActive || !deviceType) return {}

  if (deviceType === TABLET) return { height: `${window.innerHeight - 2*TABLET_HEADER_HEIGHT}px` }
  
  return { height: `${window.innerHeight - 2*MOBILE_HEADER_HEIGHT}px` }
}

export const debounce = (func, wait, immediate = false) => {
  let timeout;
  return (...args) => {
      const later = () => {
          timeout = null; // added this to set same behaviour as ES5
          if (!immediate) func(...args); // this is called conditionally, just like in the ES5 version
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func(...args);
  };
};