import React from 'react'
import { RichText } from 'prismic-reactjs';

import ImageResponsive from '../../../ImageResponsive'
import { getDate } from '../../utils';

const Slice = ({ text, image }) => {
  if (!text && !image) return null

  if (text) return RichText.render(text)

  return (
    <div className="news__content__image">
      <ImageResponsive src={image.url} alt={image.alt} />
    </div>
  )
}

const Body = ({ content, date }) => {
  if (!content?.length) return null

  return (
    <div className="news__content__body">
      {content.map((item, index) => <Slice key={`${date}-${index}`} {...item} />)}
    </div>
  )
}

const Article = ({ title, date, content, getLastDate, setLastDate }) => {
  const formattedDate = getDate(date)
  const lastDate = getLastDate()
  const isNewDate = formattedDate !== lastDate

  if (isNewDate) {
    setLastDate(formattedDate)
  }

  return (
    <>
      {isNewDate ? <p className="news__content__date">{formattedDate}</p> : null}
      <article className="news__content__article">
        <h2 className="news__content__title">{RichText.asText(title)}</h2>
        <Body content={content} date={formattedDate} />
      </article>
    </>
  )
}

const Content = ({ news }) => {
  let lastDate = null

  const getLastDate = () => lastDate
  const setLastDate = date => lastDate = date

  return (
    <div className="news__content">
      {news.map((article, index) => (
        <Article key={`article-${index}`} { ...{ getLastDate, setLastDate } } {...article} />)
      )}
    </div>
  ) 
}

export default Content