import React from 'react'
import './styles.css'

const getClassName = (cover) => {
  if (!cover) return 'image-responsive'
  return 'image-responsive image-responsive--cover'
}

const ImageResponsive = ({ src, alt = '', cover = false }) => (
  <div className={getClassName(cover)}>
    <img src={src} alt={alt} />
  </div>
)

export default ImageResponsive