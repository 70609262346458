import React, { useRef, useEffect } from 'react'
import Flickity from 'react-flickity-component'
import './styles.css';

const flickityOptions = {
  wrapAround: true,
  adaptiveHeight: false
}

const Slide = ({ dimensions: { height, width }, url, alt }) => {
  if (height > width) {
    return (
      <div className="slider__portrait">
        <div className="slider__wrapper">
          <img alt={alt} src={url} />
        </div>
      </div>
    )
  }

  return <img alt={alt} src={url} />
}

const Slider = ({ items }) => {
  const flkty = useRef(null)
  const captionNumber = useRef(null)
  const captionText = useRef(null)

  useEffect(() => {
    const getText = (slideNum) => `${slideNum}/${items.length}`

    captionNumber.current.innerText = getText('1')
    captionText.current.innerText = items[0]?.image?.alt
    
    flkty.current.on('change', () => {
      const index = flkty.current.selectedIndex
      captionNumber.current.innerText = getText(index + 1)
      captionText.current.innerText = items[index]?.image?.alt
    })
  }, [items])

  const getClassNames = () => {
    if (typeof window === "undefined" || !window.document) return 'slider'
    if ("ontouchstart" in window.document.documentElement) return 'slider slider--touch'
    return 'slider'
  }

  if (!items?.length) return null

  return (
    <div className={getClassNames()}>
      <Flickity
        flickityRef={c => (flkty.current = c)}
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {items.map(({ image }, index) => <Slide key={`sld-${index}`} {...image} />)}
      </Flickity>
      <div className="slider__caption">
        <p ref={captionNumber} className="slider__caption__number" />
        <p ref={captionText} className="slider__caption__text" />
      </div>
    </div>
  );
}

export default Slider