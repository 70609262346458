/**
 * Breakpoints
 */
export const MOBILE_MIN_WIDTH = 576;
export const TABLET_MIN_WIDTH = 770;

/**
 * Header
 */
export const MOBILE_HEADER_HEIGHT = 45
export const TABLET_HEADER_HEIGHT = 62

/**
 * Sections
 */
export const HOME = 'home';
export const ARTICLE = 'article';
export const ABOUT = 'about';
export const NEWS = 'news';

/**
 * Trasitions
 */
export const TRANSITION_TIME = 600;
