const mapBody = (body) => body.map(({ type, primary, fields }) => {
  if (type === 'slider') {
    return {
      type,
      fields
    }
  }

  const { image, text } = primary

  return {
    type,
    text,
    image,
  }
})

const mapData = ({ name, date, body }) => ({
  name,
  date,
  content: mapBody(body)
})

export const mapArticle = (data) => {
  const article = data?.prismic?.allProjects?.edges?.[0]?.node
  if (!article) return null

  
  return mapData(article)
}
