import React from 'react'
import { RichText } from 'prismic-reactjs';


const Footer = ({ hasScroll, instagram, linkedin, newsletter, copyright_text: copyright }) => {
  const className = () => {
    if (hasScroll) return 'about__footer about__footer--scroll'
    return 'about__footer'
  }

  return (
    <div className={className()}>
      <div className="about__footer__wrapper">
        <p>
          <a href={RichText.asText(instagram)} target="_blank" rel="noreferrer">Instagram</a>
          <span> · </span>
          <a href={RichText.asText(linkedin)} target="_blank" rel="noreferrer">Linkedin</a>
          <span> · </span>
          <a href={RichText.asText(newsletter)} target="_blank" rel="noreferrer">Newsletter</a>
        </p>
      </div>
    </div>
  )
}

export default Footer