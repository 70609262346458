
import React from "react"
import PropTypes from "prop-types"
import { registerLinkResolver } from 'gatsby-source-prismic-graphql'
import { linkResolver } from './src/utils'
import Layout from "./src/components/Layout"

registerLinkResolver(linkResolver)

const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

wrapPageElement.propTypes = {
  element: PropTypes.node,
  props: PropTypes.object,
}

export { wrapPageElement }
