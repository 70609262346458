import React from "react"
import { NEWS, ABOUT, TABLET_MIN_WIDTH } from "../../constants";
import './styles.css';

const getMainClasses = (isMain, isActive) => {
  let className = 'header'

  if (isMain) {
    className = className + ' header--main';
  }

  if (isActive) {
    className = className + ' header--active';
  }

  return className
}

const getPageClasses = (section, isMain, isActive) => {
  let mainClasses = getMainClasses(isMain, isActive)

  if (section === NEWS) return `${mainClasses}  header--news`
  if (section === ABOUT) return `${mainClasses}  header--about`
  return mainClasses
}

const SectionName = ({ sectionName, isMain, isActive, isNewsAndAboutIsActive }) => {
  if (typeof window !== 'undefined' && window.innerWidth < TABLET_MIN_WIDTH) return <span className="header__section">({sectionName})</span>
  if ((!isActive && isMain) || isNewsAndAboutIsActive) return null
  return <span className="header__section">({sectionName})</span>
}

const Header = ({ children, sectionName, isMain, isActive, section, page }) => {
  const isNewsAndAboutIsActive = (section === NEWS) && (page === ABOUT)

  return (
    <header className={getPageClasses(section, isMain, isActive)}>
      {children}
      <SectionName { ...{ sectionName, isMain, isActive, isNewsAndAboutIsActive } } />
    </header>
  )
}

export default Header
