import React, { useState, useEffect } from "react"
import innerHeight from 'ios-inner-height'
import Work from "../Work"
import News from "../News"
import About from "../About"
import './styles.css'
import { getPage, getDeviceType, debounce } from "../../utils"

const Layout = ({ data, location, children }) => {
  const page = getPage(location)
  const [className, setClassName] = useState('layout')
  const [lastPage, setLastPage] = useState(page)
  const [height, setHeight] = useState(innerHeight())
  const [heightStyle, setHeightStyle] = useState({})

  useEffect(() => {
    const handleResize = debounce(() => {
      setHeight(innerHeight())
    }, 500)

    window.addEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
    setClassName(`layout layout--${page} layout--previous--${lastPage}`)
    setLastPage(page)
  }, [page])

  useEffect(() => {
    let style = {}
    if (getDeviceType()) {
      style = { height, overflow: 'hidden' }
    }

    setHeightStyle(style)
  }, [height])

  return (
    <div className={className} style={{...heightStyle}}>
      {children}
      <Work key={`work-${height}`} page={page} data={data} height={height} />
      <News key={`news-${height}`} page={page} data={data} height={height} />
      <About key={`about-${height}`} page={page} data={data} height={height} />
    </div>
  )
}

export default Layout
