import React, { useState } from 'react'
import { Link } from 'gatsby'
import { ABOUT, NEWS, TABLET_MIN_WIDTH, HOME, ARTICLE } from '../../constants'
import './styles.css'

const LinkWrapper = ({ page, className, onMouseEnter, onMouseLeave, children }) => {
  const getTo = (page) => {
    if (page === HOME || page === ARTICLE) {
      return '/'
    }

    return `/${page}`
  }

  return (
    <Link
      to={getTo(page)}
      onMouseEnter={() => onMouseEnter(page)}
      onMouseLeave={onMouseLeave}
      className={className}
    >
      {children}
    </Link>
  )
}

const MobileTitle = () => (
  <h1 className="title">
    <Link to="/">Architecture Doing Place</Link>
  </h1>
)

const HomeTitle = (props) => (
  <h1 className="title title--home">
    <LinkWrapper className="title__home__space" page={HOME} {...props}>Architecture</LinkWrapper>
    <LinkWrapper className="title__news__space" page={NEWS} {...props}>Doing</LinkWrapper>
    <LinkWrapper page={ABOUT} {...props}>Place</LinkWrapper>
  </h1>
)

const NewsTitle = (props) => (
  <p className="title title--news">
    <LinkWrapper className="title__home__space" page={HOME} {...props}>A.</LinkWrapper>
    <LinkWrapper className="title__news__space" page={NEWS} {...props}>Doing</LinkWrapper>
    <LinkWrapper page={ABOUT} {...props}>P.</LinkWrapper>
  </p>
)

const AboutTitle = (props) => (
  <p className="title title--about">
    <LinkWrapper page={HOME} className="title__home__space" {...props}>A.</LinkWrapper>
    <LinkWrapper page={NEWS} className="title__news__space" {...props}>Do.</LinkWrapper>
    <LinkWrapper page={ABOUT} className="title__about__space" {...props}>Place</LinkWrapper>
  </p>
)

const Title = ({ page }) => {
  const [hovered, setHovered] = useState()

  const onMouseEnter = (page) => {
    setHovered(page)
  }
  
  const onMouseLeave = () => {
    setHovered(null)
  }

  const logo = hovered || page

  if (typeof window !== 'undefined' && window.innerWidth < TABLET_MIN_WIDTH) return <MobileTitle />
  if (logo === NEWS) return <NewsTitle { ...{ onMouseEnter, onMouseLeave } } />
  if (logo === ABOUT) return <AboutTitle { ...{ onMouseEnter, onMouseLeave } } />
  return <HomeTitle { ...{ onMouseEnter, onMouseLeave } } />
}

export default Title